import axios from "axios";
import { motion } from "framer-motion";
import useSWR from "swr";

import { ProductData } from "../../types";
import SubItem from "../SubItem/SubItem";
import styles from "./Menu.module.css";

// Your types remain the same...

// Fetcher function for SWR
const fetcher = (url: string) =>
	axios
		.get(url, {
			headers: {
				// Ensure your API key is not exposed to the client.
				Authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_API_KEY}`,
			},
		})
		.then((res) => res.data);

interface Props {
	currentMenu: null | string;
	menuOpen: boolean;
}

const MenuItemVariants = {
	hidden: {
		opacity: 0,
		y: -8,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	exit: {
		opacity: 0,
		y: -8,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

const MenuItemVariants2 = {
	visible: {
		transition: {
			delayChildren: 0.2,
			staggerChildren: 0.02,
		},
	},
};

// Note: the URL here is relative to your CMS. If your API key is sensitive,
// consider proxying the request through your Next.js API routes.
export default function Menu({ currentMenu, menuOpen }: Props) {
	const { data, error } = useSWR(
		"https://cms.gia-usa.com/api/products?populate=*",
		fetcher
	);

	if (error) return <div>Error loading products</div>;
	if (!data) return null;

	const products: ProductData[] = data.data;
	return (
		<motion.div
			className={styles.navigationMenu}
			layout
			initial="hidden"
			animate={menuOpen ? "visible" : "hidden"}
			exit="exit"
			variants={MenuItemVariants}
			style={{
				borderRadius: "16px",
				boxShadow:
					"0 13.5px 27px -12.4px #32325d40, 0 8.1px 16.2px -8.1px #0000001a",
				border: "1px solid rgb(227, 232, 238)",
			}}
		>
			{currentMenu === "Products" && (
				<motion.div
					className={styles.subItemContainerTest}
					layout
					initial="hidden"
					animate="visible"
					variants={MenuItemVariants2}
				>
					{products.map((product) => (
						<SubItem
							key={product.attributes.slug}
							title={product.attributes.hero_title}
							href={`/products/${product.attributes.slug}`}
							internal={true}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{ width: "100%", height: "100%" }}
							>
								<g clipPath="url(#clip0_1_2)">
									<path
										d={product.attributes.icon_svg_d}
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</svg>
						</SubItem>
					))}
				</motion.div>
			)}
			{currentMenu === "Resources" && (
				<motion.div
					className={styles.subItemContainerTest}
					layoutId="menu2"
					initial="hidden"
					animate="visible"
					variants={MenuItemVariants2}
				>
					<SubItem
						title="About"
						href="/resources/about"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_16_88)">
								<path
									d="M4 19V5C4 4.46957 4.21071 3.96086 4.58579 3.58579C4.96086 3.21071 5.46957 3 6 3H19.4C19.5591 3 19.7117 3.06321 19.8243 3.17574C19.9368 3.28826 20 3.44087 20 3.6V16.714"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M8 3V11L10.5 9.4L13 11V3"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6 17H20M6 21H20"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M6 21C5.46957 21 4.96086 20.7893 4.58579 20.4142C4.21071 20.0391 4 19.5304 4 19C4 18.4696 4.21071 17.9609 4.58579 17.5858C4.96086 17.2107 5.46957 17 6 17"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Contact"
						href="https://rater.gia-usa.com/contact"
						internal={false}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_16_67)">
								<path
									d="M7.50001 22C8.71071 21.9998 9.88753 21.6001 10.8479 20.863C11.8084 20.1258 12.4987 19.0924 12.8119 17.9229C13.1251 16.7534 13.0437 15.5132 12.5803 14.3947C12.1169 13.2762 11.2973 12.3419 10.2488 11.7366C9.20023 11.1314 7.98127 10.889 6.78094 11.0471C5.5806 11.2052 4.46598 11.7549 3.60991 12.6111C2.75385 13.4672 2.2042 14.5819 2.04619 15.7822C1.88819 16.9826 2.13066 18.2015 2.73601 19.25L2.27501 21.725L4.75001 21.265C5.58583 21.7482 6.53457 22.0018 7.50001 22V22Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M15.282 17.898C16.2395 17.7439 17.1607 17.4158 18 16.93L21.6 17.6L20.93 14C21.5183 12.9795 21.8739 11.8416 21.9716 10.6678C22.0692 9.49388 21.9064 8.31292 21.4948 7.20924C21.0832 6.10556 20.4329 5.1064 19.5905 4.28312C18.748 3.45983 17.7341 2.83275 16.6213 2.44665C15.5084 2.06056 14.324 1.92498 13.1527 2.04961C11.9814 2.17423 10.852 2.55598 9.84531 3.16759C8.8386 3.7792 7.97938 4.60558 7.32901 5.58769C6.67864 6.5698 6.25318 7.68342 6.08301 8.849"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Claims"
						href="https://rater.gia-usa.com/contact"
						internal={false}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_16_82)">
								<path
									d="M12 8V12M12 16.01L12.01 15.999M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 13.821 2.487 15.53 3.338 17L2.5 21.5L7 20.662C8.51954 21.5411 10.2445 22.0027 12 22V22Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Privacy Policy"
						href="/resources/privacy"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_16_59)">
								<path
									d="M21.8 14C20.873 18.564 16.838 22 12 22C6.477 22 2 17.523 2 12C2 6.815 5.947 2.551 11 2.05"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M7 15.01L7.01 14.999M17 15.01L17.01 14.999M11 12.01L11.01 11.999M21 9.01L21.01 8.999M17 6.01L17.01 5.999M11 2C10.5 3.5 11.5 5 13.085 5C11 8.5 13 12 18 11.5C18 14 20.5 14.5 21.7 14.014M6.5 10C6.36739 10 6.24021 9.94732 6.14645 9.85355C6.05268 9.75979 6 9.63261 6 9.5C6 9.36739 6.05268 9.24021 6.14645 9.14645C6.24021 9.05268 6.36739 9 6.5 9C6.63261 9 6.75979 9.05268 6.85355 9.14645C6.94732 9.24021 7 9.36739 7 9.5C7 9.63261 6.94732 9.75979 6.85355 9.85355C6.75979 9.94732 6.63261 10 6.5 10ZM20.5 4C20.3674 4 20.2402 3.94732 20.1464 3.85355C20.0527 3.75979 20 3.63261 20 3.5C20 3.36739 20.0527 3.24021 20.1464 3.14645C20.2402 3.05268 20.3674 3 20.5 3C20.6326 3 20.7598 3.05268 20.8536 3.14645C20.9473 3.24021 21 3.36739 21 3.5C21 3.63261 20.9473 3.75979 20.8536 3.85355C20.7598 3.94732 20.6326 4 20.5 4ZM12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18C11 17.7348 11.1054 17.4804 11.2929 17.2929C11.4804 17.1054 11.7348 17 12 17C12.2652 17 12.5196 17.1054 12.7071 17.2929C12.8946 17.4804 13 17.7348 13 18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
					<SubItem
						title="Terms"
						href="/resources/terms"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_16_55)">
								<path
									d="M6 6H18M6 10H18M13 14H18M13 18H18M2 21.4V2.6C2 2.44087 2.06321 2.28826 2.17574 2.17574C2.28826 2.06321 2.44087 2 2.6 2H21.4C21.5591 2 21.7117 2.06321 21.8243 2.17574C21.9368 2.28826 22 2.44087 22 2.6V21.4C22 21.5591 21.9368 21.7117 21.8243 21.8243C21.7117 21.9368 21.5591 22 21.4 22H2.6C2.44087 22 2.28826 21.9368 2.17574 21.8243C2.06321 21.7117 2 21.5591 2 21.4V21.4Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6 18V14H9V18H6Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
				</motion.div>
			)}
			{currentMenu === "Agents" && (
				<motion.div
					className={styles.subItemContainerTest}
					layoutId="menu2"
					initial="hidden"
					animate="visible"
					variants={MenuItemVariants2}
				>
					<SubItem
						title="Insurance"
						href="/agents/wholesaleinsurance"
						internal={true}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "100%", height: "100%" }}
						>
							<g clipPath="url(#clip0_17_108)">
								<path
									d="M14 5C14 4.46957 13.7893 3.96086 13.4142 3.58579C13.0392 3.21071 12.5304 3 12 3C11.4696 3 10.9609 3.21071 10.5858 3.58579C10.2107 3.96086 10 4.46957 10 5M19.26 9.696L20.645 18.696C20.6888 18.9808 20.6705 19.2718 20.5914 19.5489C20.5123 19.8261 20.3743 20.0828 20.1867 20.3016C19.9992 20.5204 19.7665 20.6961 19.5047 20.8167C19.243 20.9372 18.9582 20.9997 18.67 21H5.33001C5.04164 21 4.75668 20.9377 4.49465 20.8173C4.23263 20.6969 3.99974 20.5212 3.81196 20.3024C3.62417 20.0836 3.48593 19.8267 3.40672 19.5494C3.3275 19.2721 3.30918 18.981 3.35301 18.696L4.73801 9.696C4.81066 9.22359 5.05009 8.79282 5.41294 8.4817C5.77578 8.17059 6.23805 7.9997 6.71601 8H17.284C17.7618 7.99994 18.2238 8.17094 18.5865 8.48203C18.9491 8.79312 19.1884 9.22376 19.261 9.696H19.26Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</SubItem>
				</motion.div>
			)}
		</motion.div>
	);
}
